.MuiSvgIcon-root {
  display: none !important;
}

td.MuiTableCell-root,
th.MuiTableCell-root,
.MUIDataTableHeadCell-fixedHeader-51,
.MUIDataTableSelectCell-headerCell-41 {
  z-index: 0 !important;
}
